
.background-image{ 
    height: 720px;
      width: 1390px;
  }
  .logo-image{
    position: relative;
    width: 350px;
    
    
  }
  
  .quota-container{
   position: fixed;
   top: 50%;
   left: 50%;
   margin-top: -50px;
   margin-left: -250px;
  }
  
  .success-logo{
   position: fixed;
   top: 20px;
   left: 50%;
   width: 20%;
  }
  
  .success-text{
   position: fixed;
   top: 125px;
   left: 40%;
   width: 50%;
   text-align: center;
  }
  
  .terminate-container{
   position: fixed;
   top: 50%;
   left: 50%;
   margin-top: -50px;
   margin-left: -150px;
  }