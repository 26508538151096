body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.background-image{ 
    height: 720px;
      width: 1390px;
  }
  .logo-image{
    position: relative;
    width: 350px;
    
    
  }
  
  .quota-container{
   position: fixed;
   top: 50%;
   left: 50%;
   margin-top: -50px;
   margin-left: -250px;
  }
  
  .success-logo{
   position: fixed;
   top: 20px;
   left: 50%;
   width: 20%;
  }
  
  .success-text{
   position: fixed;
   top: 125px;
   left: 40%;
   width: 50%;
   text-align: center;
  }
  
  .terminate-container{
   position: fixed;
   top: 50%;
   left: 50%;
   margin-top: -50px;
   margin-left: -150px;
  }
